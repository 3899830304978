/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import Post from "../components/Post";
import Layout from "../components/Layout";

export default function BlogTemplate(props) {
  const { data } = props;
  const post = data && data.post;
  return <Layout>{post && <Post post={post} />}</Layout>;
}

export const query = graphql`
  query BlogTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      title
      _rawBody
      _rawFootnotes
      publishedAt
      slug {
        current
      }
    }
  }
`;
